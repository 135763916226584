import { Box, Stack } from "@mui/material";
import { parlorRegion } from "@/constants/parlorRegion";
import {
  DTypeCardView,
  ColorIcon,
  FUNERAL_SIDO,
  FUNERAL_SIGUNGU,
  colors,
  getCdnImageSrc,
  FitWidthSmallButton,
  GOI_WEB_URL,
  call,
  Link,
} from "goi_common";

import parlors from "goi_common/src/constants/parlors_detail.json";

const { targetSidos, regionColor } = parlorRegion;

export default function Home() {
  const regionParlors = parlors.filter((p) => targetSidos.includes(p.sido));
  return (
    <Stack style={{ background: "white" }}>
      <Stack
        className="caption_12_sb"
        padding="0 16px"
        flexDirection="row"
        gap="16px"
        marginBottom="1px"
      >
        <Box padding="8px 0" borderBottom="3px solid black">
          장례식장 정보
        </Box>
        <Link
          className="gray600"
          style={{ textDecoration: "none", padding: "8px 0" }}
          href="/free-benefit/"
          event={{
            domain: "honam",
            path: "home",
            trigger_and_target: "uv_basic_livelihood",
          }}
        >
          기초생활수급자
        </Link>
        <Link
          className="gray600"
          style={{ textDecoration: "none", padding: "8px 0" }}
          href={GOI_WEB_URL + "/service/flower/"}
          event={{
            domain: "honam",
            path: "home",
            trigger_and_target: "uv_wreath_req",
          }}
        >
          화환 보내기
        </Link>
      </Stack>
      <Stack
        height="400px"
        padding="48px 20px"
        style={{
          background: `url(${getCdnImageSrc(
            "dogsound/honam/homebackground.png"
          )})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Stack gap="20px">
          <Box>
            <Box className="subtitle_14_sb gray700">장례가 필요할 때</Box>
            <Box
              fontFamily="GmarketSans"
              fontSize="18px"
              fontWeight={700}
              className="white"
              component="h2"
            >
              전라광주지역장례추천포털
              <span className="orange650">.</span>
            </Box>
          </Box>
          <Stack>
            <FitWidthSmallButton
              text="장례 견적 알아보기"
              backgroundColor={regionColor.c400}
              href={GOI_WEB_URL + "/match/request/"}
              event={{
                domain: "honam",
                path: "home",
                trigger_and_target: "uv_mth_req",
              }}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack
        flexDirection="row"
        justifyContent="center"
        gap="44px"
        padding="24px 0 60px"
      >
        <Stack
          alignItems="center"
          onClick={() => {
            call("1666-9784");
          }}
        >
          <ColorIcon name="symbol-call" size={56} color="black" />
          <Box className="subtitle_14_sb">긴급 장례 상담</Box>
        </Stack>
        <Box height="65px" width="1px" style={{ background: colors.gray500 }} />
        <Link
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          href={GOI_WEB_URL + "/service/flower/"}
          event={{
            domain: "honam",
            path: "home",
            trigger_and_target: "uv_wreath_req",
          }}
        >
          <ColorIcon name="flower-line" size={56} color="black" />
          <Box
            className="subtitle_14_sb black"
            style={{ textDecoration: "none" }}
          >
            화환 2시간 배송
          </Box>
        </Link>
      </Stack>
      <Stack alignItems="center" className="subtitle_16_b gray900" mb="6px">
        <h2>전라 광주 장례식장 추천 목록 </h2>
      </Stack>
      <Stack padding="0 20px" gap="10px">
        {regionParlors.map((res) => (
          <DTypeCardView
            address={res.address}
            key={res.companyname}
            name={res.companyname}
            href={`/funeral-recommend-item/${
              res.companyname
            }-(${FUNERAL_SIDO.get(Number(res.sido))} ${FUNERAL_SIGUNGU.get(
              Number(res.sido)
            )?.get(Number(res.sigungu))})/`}
            descriptions={[
              `평균 ${Number(res.binso_average_charge).toLocaleString()}원`,
            ]}
            imgSrc={res.images[0]?.url ? res.images[0].url : ""}
            event={{
              domain: "honam",
              path: "home",
              trigger_and_target: "uv_prlr_detail",
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
}
